<template>
  <div>
    <validation-observer
      ref="form"
      #default="{invalid}"
    >
      <b-form
        v-if="!loading"
        class="auth-login-form mt-2"
        @submit.prevent="submit"
      >
        <validation-provider
          v-if="!isHired"
          #default="{ errors }"
          :name="$t('Количество продукта')"
          rules="required"
        >
          <b-form-group
            :label="$t('Количество продукта')"
            label-for="quantity"
          >
            <b-form-input
              id="quantity"
              v-model="form.quantity"
              type="number"
              :state="errors.length > 0 ? false:null"
              @input="changeQuantity(form.quantity)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <b-form-group
          :label="$t('Написать причина')"
          label-for="comment"
        >
          <validation-provider
            #default="{ errors }"
            :name="$t('Написать причина')"
            rules="required"
          >
            <b-form-textarea
              id="comment"
              v-model="form.comment"
              class="form-control"
              :state="errors.length > 0 ? false:null"
              placeholder="Причина отказа"
              rows="3"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <div class="mt-2 text-right">
          <b-button
            :disabled="loading"
            type="submit"
            variant="primary"
            class="mr-1"
          >
            Подтверждать
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { showToast } from '@/utils/toast'
import { required } from '@validations'

export default {
  components: {
    ValidationProvider, ValidationObserver,
  },
  props: {
    product: {
      type: Object,
      default: () => {},
    },
    isHired: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        comment: null,
        quantity: null,
        barcode: null,
        product_id: null,
      },
      loading: false,
      comments: null,
      required,
    }
  },
  watch: {
  },
  mounted() {
    if (this.product) {
      this.form.barcode = this.product.barcode
      this.form.product_id = this.product.id
    }
  },
  methods: {
    async validationForm() {
      let validated = false
      await this.$refs.form.validate().then(success => {
        validated = success
      })
      return validated
    },
    changeQuantity(val) {
      if (val && (Number(this.form.quantity) > Number(this.product.quantity))) {
        this.form.quantity = Number(this.product.quantity)
      }
    },
    async submit() {
      const valid = await this.validationForm()
      if (valid) {
        this.loading = true
        this.$emit('submit', this.form)
        this.loading = false
      }
    },
  },
}
</script>

<style>

</style>
